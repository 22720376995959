import React, { useState, useRef, useEffect } from 'react';
import Layout from '../containers/layout';
import { sendEmail } from '../utils/tools';

import Mailgun from 'mailgun.js';

const EmailTest = () => {
  useEffect(() => {
    sendEmail({
      from: 'Academy of Art School of Industrial Design <noreply@academyart.edu>',
      to: 'svu@academyart.edu',
      subject: 'Testing sending emails from Industrial Design web (env vars)',
      text: 'This is a test.  This is only a test ...',
      html: '<strong>This is a test.  This is only a test ...</strong>',
    });

    //   const mailgun = new Mailgun(FormData);
    //   const mg = mailgun.client({
    //     username: 'api',
    //     key: process.env.GATSBY_MAILGUN_API_KEY,
    //   });

    //   mg.messages
    //     .create(process.env.GATSBY_MAILGUN_DOMAIN, {
    //       from: 'Academy of Art School of Industrial Design <noreply@academyart.edu>',
    //       to: 'stevedvu@gmail.com',
    //       subject: 'Testing sending emails from Industrial Design web',
    //       text: 'This is a test.  This is only a test ...',
    //       html: '<strong>This is a test.  This is only a test ...</strong>',
    //     })
    //     .then((msg) => console.log(`--- SVU: successfully sent email`, msg))
    //     .catch((error) => console.log(`--- SVU: error caught sending email`, error));
  }, []);

  return (
    <Layout dark>
      <p>This is EmailTest</p>
    </Layout>
  );
};

export default EmailTest;
